<template>
  <div>
    <v-container>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              {{ customerData.fullName }}
            </v-card-title>

            <v-card-subtitle>
              Puoi modificare o inserire i dati del tuo profilo
            </v-card-subtitle>

            <v-card-text>
              <v-form
                ref="formProfile"
                @submit.prevent
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="3"
                  >
                    <procedure-users-avatar
                      :user="customerData"
                      :size="100"
                    ></procedure-users-avatar>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                  >
                    <v-text-field
                      v-model="customerData.name"
                      label="Nome"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="customerData.surname"
                      label="Cognome"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-if="customerData.role === 'user'"
                      v-model="customerData.cf"
                      label="Codice Fiscale"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="customerData.email"
                      label="Email"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="customerData.phone1"
                      label="Telefono 1"
                      outlined
                      required
                      dense
                      readonly
                    ></v-text-field>
                    <v-text-field
                      v-model="customerData.phone2"
                      label="Telefono 2"
                      outlined
                      dense
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col
          cols="12"
        >
          <v-card>
            <v-card-title class="text-h5">
              Elenco pratiche
            </v-card-title>

            <v-card-subtitle>
              Di seguito trovi l'elenco delle pratiche instaurate con il cliente
            </v-card-subtitle>

            <v-card-text>
              <v-simple-table>
                <thead>
                  <tr>
                    <th>Data Creazione</th>
                    <th>Nome Pratica</th>
                    <th>Stato</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(procedure, idx) in customerData.procedures"
                    :key="'A'+idx"
                  >
                    <td>{{ procedure.createdAtFormatted }}</td>
                    <td>{{ procedure.title }}</td>
                    <td>{{ procedure.statusText }}</td>
                    <td>
                      <v-btn
                        class="ma-2"
                        color="primary"
                        dark
                        small
                        :to="procedure.linkTo"
                      >
                        <v-icon dark>
                          mdi-eye
                        </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProcedureUsersAvatar from '@/components/procedure/procedure-users-avatar.vue'

export default {
  name: 'CustomerDetail',
  components: { ProcedureUsersAvatar },
  computed: {
    ...mapGetters(['customersList']),
  },
  data() {
    return {
      customerData: {},
      breadCrumb: [
        {
          text: 'Dashboard',
          to: '/dashboard',
        },
        {
          text: 'Clienti',
          to: '/customers',
        },
        {
          text: 'Dettaglio cliente',
          to: `/customer/${this.$route.params.cid}`,
        },
      ],
    }
  },
  mounted() {
    this.getData()
    this.$store.dispatch('setBreadCrumbLevels', this.breadCrumb)
  },
  methods: {
    async getData() {
      const customerId = parseInt(this.$route.params.cid, 10)
      this.customerData = await this.customersList.find(item => item.id === customerId)
      if (this.customerData === undefined) {
        await this.$router.push('/customers')
      }
    },
  },
}
</script>
